import { FakedCodelists } from '@administration/codelists/codelists.interface';
import { Codelists } from '@administration/codelists/codelists.service';
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    inject,
    Injector,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Setting } from '@common/interfaces/setting.interface';
import { SettingKeyCodes } from '@common/known-types/app-setting-key.codes';
import { ViewMode } from '@common/models/view-mode';
import { CommonService } from '@common/services/common.service';
import { QueryService } from '@common/services/query.service';
import { environment } from '@environments/environment';
import { tapSuccessResult } from '@ngneat/query';
import { ComboBoxComponent, PopupSettings } from '@progress/kendo-angular-dropdowns';
import { FileInfo, FileRestrictions, SelectEvent } from '@progress/kendo-angular-upload';
import { addDays } from 'date-fns';
import _ from 'lodash';

export type AppControlType =
    | 'string'
    | 'textarea'
    | 'boolean'
    | 'datetime'
    | 'date'
    | 'number'
    | 'password'
    | 'codelist'
    | 'static'
    | 'select'
    | 'file'
    | 'yesno'
    | 'flag'
    | 'color'
    | 'vesselType'
    | 'seal'
    | 'mots';

export interface ColorPalette {
    [colorHex: string]: string;
}

export interface FormProperty {
    name: string;
    type?: AppControlType;
    label?: string;
    codelist?: Codelists | FakedCodelists;
    initialValue?: any;
    valueField?: string;
    multi?: boolean;
    time?: boolean;
    pattern?: string;
    allowedExtensions?: string[];
    required?: boolean;
    options?: string[];
    placeholder?: string;
    colSpan?: number;
    format?: string;
    decimals?: number;
    rows?: number;
    nullable?: boolean;
    filter?: (item, search) => boolean;
    isDisabled?: (model: any) => boolean;
    disabledDates?: (date: Date) => boolean;
}

/**
 * @deprecated Use app-input component or specific inputs instead.
 */
@Component({
    selector: 'app-control',
    templateUrl: 'app-control.component.html',
    styleUrls: ['app-control.component.scss'],
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: AppControlComponent, multi: true }],
    encapsulation: ViewEncapsulation.None
})
export class AppControlComponent implements OnInit, ControlValueAccessor, OnChanges, OnDestroy {
    private _date: Date;
    private _omittedCodelistIDs: string[] = []; // List of IDs to ignore when fetching codelists. Eg.: when listing possible remaining choices for languages in translations, when adding a new one
    filteredOptions = [];
    error: string;
    codelistTake = environment.settings.appControl.codelist.take;
    private val: any;
    onChange: any = _.noop;
    isMouseEnter: boolean;
    isClicked: boolean;
    decimalFormat = '1.3-3';
    characterCount: number = 0;
    codelist$;
    query$;
    injector = inject(Injector);

    defaultDateFormat: string;
    defaultTimeFormat: string;
    defaultDateTimeFormat: string;

    @ViewChild('combobox') public combobox: ComboBoxComponent;

    @Input() options = [];
    @Input() label: string;
    @Input() type: AppControlType;
    @Input() ngModel: NgModel | any;
    @Input() isDisabled: boolean = false;
    @Input() customRoute?: { path: string; field: string };
    @Input() codelist: Codelists | FakedCodelists;
    @Input() codelistFilter = '';
    @Input() textField = 'label';
    @Input() multi = environment.settings.appControl.multi;
    @Input() required = false;
    @Input() filter: (item, search) => boolean;
    @Input() format: string;
    @Input() min: number;
    @Input() max: number;
    @Input() fetch: () => Promise<any[]>;
    @Input() popupSettings: Partial<PopupSettings> = environment.settings.appControl.dropdown.popupSettings;
    @Input() hasValue = false;
    @Input() placeholder: string;
    @Input() initialValue: string;
    @Input() valueField = 'value';
    @Input() time = environment.settings.appControl.time;
    @Input() timeOnly = false;
    @Input() decimals = 0;
    @Input() rows: number; // Textarea rows
    @Input() maxlength?: number;
    @Input() click;
    @Input() palette: ColorPalette = {
        '#EB1515': 'red',
        '#ECE93E': 'yellow',
        '#38E02F': 'green',
        '#F3A51E': 'orange',
        '#0096FF': 'blue',
        '#9B51E0': 'purple'
    };
    @Input() futureOnly = false; // Allow only dates in the future
    @Input() selectLabel = (item) => `${item.customText || item.name}`;
    @Input() modelAsDate = false;
    @Input() disableTooltip = false;
    @Input() pattern: string;
    @Input() useCache = true;
    @Input() disabledDates: (date: Date) => boolean = (date: Date) => this.futureOnly && addDays(date, 1) < new Date();
    @Input() fileRestrictions: FileRestrictions = environment.settings.appControl.fileRestrictions;
    @Input() yesNoValues = [
        { value: true, text: 'Yes' },
        { value: false, text: 'No' }
    ];
    @Input() selectedItem: any = null;
    @Input() isFilterable = true;
    @Input() clearButton = true;
    @Input() set omittedCodelistIds(IDs: string[]) {
        // do not omit the ID from the query if it's currently selected in this codelist. This happens when we edit an existing grid row for example.
        this._omittedCodelistIDs = IDs.filter((id) => id !== this.value);
    }
    get omittedCodelistIds() {
        return this._omittedCodelistIDs;
    }
    @Input() isEditMode: boolean = true;

    // For boolean inputs
    @Input() nullable: boolean;
    tape = [true, null, false];

    // we need this as an input to avoid autocomplete in some browsers.
    @Input() autocomplete = 'off';

    @Output() ngModelChange = new EventEmitter<any>();
    // @Output() open = new EventEmitter<any>();
    @Output() valueChange = new EventEmitter<any>();
    @Output() customBlur = new EventEmitter<void>();
    @Output() filterChange = new EventEmitter<any>();

    constructor(
        private activatedRoute: ActivatedRoute,
        private cdRef: ChangeDetectorRef,
        private queryService: QueryService,
        private commonService: CommonService
    ) {}

    set value(value) {
        this.val = value;
        this.onChange(value);
    }

    get value() {
        return this.val;
    }

    get dateValue() {
        if (_.isDate(this.val)) {
            return this.val;
        }

        if (_.isString(this.val)) {
            const date = new Date(this.val);
            // date = new Date(date.getTime() + (date.getTimezoneOffset() * 60_000));

            if (!date || !this._date || date.getTime() !== this._date.getTime()) {
                this._date = date;
            }

            return this._date;
        }

        return this.val;
    }

    set dateValue(value) {
        this._date = value;
        this.value = _.isDate(value) ? (this.modelAsDate ? value : value.toISOString()) : value;
    }

    get booleanValue() {
        return this.val;
    }

    set booleanValue(value) {
        this.value = this.nullable ? this.tape[(this.tape.indexOf(this.value) + 1) % this.tape.length] : value;
    }

    get colorHexPalette() {
        return Object.keys(this.palette || {});
    }

    get colorLabel() {
        return `${this.label}: ${this.palette?.[this.value?.toUpperCase()]}`;
    }

    async ngOnInit() {
        this.maybeApplyActive();
        this.getDefaultDateFormats();
        this.type ||= 'string';
        if (this.codelist) this.queryCodelistDebounced(this.filter);

        if (this.type === 'codelist' && this.fetch && this.value) {
            this.options = this.filteredOptions = [{ code: this.value, label: this.value }];
        }

        if (
            this.isDisabled === undefined &&
            this.activatedRoute.snapshot.data &&
            this.activatedRoute.snapshot.data.mode === ViewMode.view
        ) {
            this.isDisabled = true;
        }
        // Execute after content has been checked
        if (this.initialValue) setTimeout(() => (this.value = this.initialValue));
    }

    ngOnChanges(changes) {
        const ngModelValue = changes?.ngModel?.currentValue;
        if (changes.ngModel) this.writeValue(ngModelValue);

        if (changes?.initialValue?.firstChange) this.writeValue(changes.initialValue.currentValue);

        this.error =
            this.required && ([null, undefined].includes(this.ngModel) || (this.ngModel as any).length === 0)
                ? `${this.label || 'Property'} is required`
                : null;

        if (changes?.options && ['codelist', 'select'].includes(this.type)) {
            this.filteredOptions = this.options;
        }

        if (changes && this.type === 'yesno') this.filteredOptions = this.yesNoValues;

        if (changes?.codelist) {
            const initialValue = changes.initialValue?.currentValue;
            this.filterCodeList(initialValue || '');
        }

        if (ngModelValue !== this.value && !this.initialValue) this.value = ngModelValue || this.value; // Value change is called from parent component

        this.maybeApplyActive();

        if (['textarea', 'string'].includes(this.type) && this.maxlength) {
            this.characterCount = this.value?.length || 0;
        }
    }

    ngOnDestroy(): void {
        if (this.codelist$) {
            this.codelist$.unsubscribe();
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(): void {}

    writeValue(obj: any): void {
        this.val = obj;
        // To prevent showing 'undefined' string text
        if (['string', 'textarea'].includes(this.type) && this.value === undefined)
            setTimeout(() => (this.value = null));
        this.maybeApplyActive();
    }

    queryCodelist(filter = this.codelistFilter) {
        if (this.codelist$) this.codelist$.unsubscribe();
        this.query$ = this.queryService.getCodelistQuery({
            name: this.codelist,
            filter,
            selectedIds: this.value ? [this.value].flat() : null,
            omittedIds: this.omittedCodelistIds,
            injector: this.injector
        }).result$;
        this.codelist$ = this.query$
            .pipe(
                tapSuccessResult<any>((data: []) => {
                    this.options = data.map((x: any) => ({
                        label: this.selectLabel(x),
                        value: x.id,
                        name: x.name
                    }));
                    const currentValue = this.multi ? this.value?.[0] : this.value;
                    this.filteredOptions = this.options.filter(
                        (x, i) => x.value === currentValue || i < this.codelistTake + 1
                    ); // +1 if selected value is not in first 100
                    if (_.isFunction(this.filter))
                        this.filteredOptions = this.filteredOptions.filter((x: any) => this.filter(x, filter));
                    if (this.required && this.filteredOptions.length === 1 && !this.value)
                        this.value = this.filteredOptions[0].value; // If only one item is returned in the dropdown and input is required, automatically select it if no current selection
                })
            )
            .subscribe();
    }

    queryCodelistDebounced = _.debounce((filter) => this.queryCodelist(filter), 200);

    filterCodeList($event) {
        const searchString = typeof $event === 'number' ? $event : $event.toLowerCase();
        if (this.type === 'codelist') return this.queryCodelistDebounced(searchString);

        this.filteredOptions = _.take(
            this.options.filter((o) => o.label.toLowerCase().includes(searchString || '')),
            this.codelistTake
        );

        if (_.isFunction(this.filter)) {
            this.filteredOptions = this.filteredOptions.filter((x: any) => this.filter(x, searchString));
        }

        return Promise.reject(null);
    }

    async onDropdownOpen() {
        if (this.fetch) {
            const data = await this.fetch();
            this.options = this.filteredOptions = data;
        }
    }

    fileSelect(event: SelectEvent) {
        event.files.forEach((file: FileInfo) => {
            this.loadFile(file, (content) => {
                if (this.multi) {
                    this.value.shift();
                    this.value.push({ content, name: file.name });
                } else {
                    this.value = { content, name: file.name };
                }
            });
        });
    }

    private loadFile(file: FileInfo, onLoaded: (content: string) => void) {
        if (file.validationErrors?.length) {
            return;
        }

        const reader = new FileReader();
        reader.onload = (ev) => {
            const str = <string>ev.target.result; // data:text/plain;base64,[CONTENT]
            onLoaded(str.substring(str.indexOf(',') + 1));
        };
        reader.readAsDataURL(file.rawFile);
    }

    maybeApplyActive() {
        this.hasValue = ![null, undefined].includes(this.value) && this.value?.length !== 0;
        this.cdRef.detectChanges();
    }

    focusInHandler() {
        this.hasValue = !!this.value || !this.isDisabled;
        this.isClicked = true;
        this.cdRef.detectChanges();
    }

    focusOutHandler() {
        this.maybeApplyActive();
        this.isClicked = false;

        this.customBlur.emit();
    }

    onKeyPress(event: KeyboardEvent) {
        if (!this.pattern) return;
        if (!new RegExp(this.pattern).test(event.key)) event.preventDefault();
    }

    toggleCheckbox(checkbox: HTMLInputElement) {
        if (!this.isDisabled) {
            checkbox.checked = !checkbox.checked;
            this.value = checkbox.checked;
        }
    }

    /**
     * Filters array of options to get a string of option labels for view mode
     * @returns {string} string of selected options
     */
    get selectViewValue(): string {
        if (!this.options) return '';
        if (this.multi) {
            return this.value
                ?.map((val) => this.options.find((option) => option.value === val)?.label)
                .filter(Boolean)
                .join(', ');
        } else {
            const selectedItem = this.options.find((item) => item.value === this.value);
            return selectedItem?.label ?? '';
        }
    }

    private getDefaultDateFormats() {
        const settings = this.commonService.queryService.queryClient.getQueryData<Setting[]>(['settings']);
        if (!settings) return;
        const defaultDateFormat = settings.find(
            (setting: Setting) => setting.key === SettingKeyCodes.DateFormat
        )?.value;
        if (defaultDateFormat) this.defaultDateFormat = defaultDateFormat;
        const defaultTimeFormat = settings.find(
            (setting: Setting) => setting.key === SettingKeyCodes.TimeFormat
        )?.value;
        if (defaultTimeFormat) this.defaultTimeFormat = defaultTimeFormat;
        this.defaultDateTimeFormat = `${this.defaultDateFormat} ${this.defaultTimeFormat}`;
    }

    getTooltip(value: any) {
        if (typeof value === 'number') return;
        return value;
    }
}
