import { Component, EventEmitter, OnInit, ViewChild, signal } from '@angular/core';
import { Router } from '@angular/router';
import { ActionBarGroup } from '@common/components/action-bar/action-bar.interface';
import { FormProperty } from '@common/components/app-control/app-control.component';
import { AppGridComponent } from '@common/components/app-grid/app-grid.component';
import { UploadActionButtonsComponent } from '@common/components/upload-action-buttons.component';
import { ColumnSettings } from '@common/interfaces/column-settings.interface';
import { CommandParams } from '@common/interfaces/command-params.interface';
import { GridSettings } from '@common/interfaces/grid-settings.interface';
import { User } from '@common/models/user';
import { ViewMode } from '@common/models/view-mode';
import { CommonService } from '@common/services/common.service';
import { DialogService } from '@common/services/dialog.service';
import { RememberStateService } from '@common/services/remember-state.service';
import { ToastrNotificationService } from '@common/services/toastr-notification.service';
import { environment } from '@environments/environment';
import { SignalQueryResult } from '@ngneat/query';
import { TranslateService } from '@ngx-translate/core';
import { DialogCloseResult, DialogSettings } from '@progress/kendo-angular-dialog';
import { GridComponent } from '@progress/kendo-angular-grid';
import { DrawerComponent } from '@progress/kendo-angular-layout';
import _ from 'lodash';
import { UserService } from '../services/user.service';
import { ExportType } from './export-type';

@Component({ template: '' })
export abstract class BaseListComponent<TQuery = any> implements OnInit {
    @ViewChild('drawer') drawer: DrawerComponent;
    protected toastrNotificationService: ToastrNotificationService;
    protected router: Router;
    protected userService: UserService;
    protected translateService: TranslateService;
    protected dialogService: DialogService;
    protected rememberStateService: RememberStateService;
    protected exportPDFCommand: string;
    protected exportExcelCommand: string;
    protected exportCSVCommand: string;
    defaultViewMode = environment.settings.view.defaultViewMode;

    filtersChanged = new EventEmitter<any[]>();

    actionBar: ActionBarGroup[];
    @ViewChild(AppGridComponent) public appGrid: AppGridComponent;
    query: SignalQueryResult<TQuery, Error> = signal(null);
    user: User;
    isBusy = false;
    filterOpen = false;
    filter: any;
    createPermission: string;
    public selection = [];
    /**
     * @deprecated
     * Here until pages are refactored to use new NavigationService. This will be removed in the future.
     */
    breadcrumbs = null;
    /**
     * @deprecated
     * Here until pages are refactored to use new NavigationService. This will be removed in the future.
     */
    currentNavItem = null;

    parentRoute = window.location.pathname.split('/').slice(0, -1).join('/');
    abstract columns: ColumnSettings[];
    abstract queryName: string;
    persistFilter = environment.settings.list.persistFilter;
    private rememberFilterState = environment.settings.list.rememberFilterState;

    protected constructor(protected common: CommonService) {
        this.toastrNotificationService = common.toastrNotificationService;
        this.router = common.router;
        this.userService = common.userService;
        this.translateService = common.translateService;
        this.dialogService = common.dialogService;
        this.rememberStateService = common.rememberStateService;

        this.search = _.debounce(this.search.bind(this), 50);
        this.user = this.userService.currentUserSubject.getValue();

        if (this.rememberFilterState)
            this.filterOpen = this.rememberStateService.get<boolean>(`filterExpanded:${this.router.url}`) || false;

        this.actionBar = [
            {
                label: 'Actions',
                items: [
                    {
                        label: 'New',
                        icon: 'faSolidPlus',
                        iconOnly: true,
                        variant: 'primary',
                        isVisible: () => this.canCreateNew(),
                        onClick: () => this.createNew()
                    }
                ]
            },
            {
                label: 'Export',
                items: [
                    {
                        label: 'Excel',
                        icon: 'faSolidFileExcel',
                        iconOnly: true,
                        variant: 'excel',
                        isVisible: () => this.canExportExcel(),
                        onClick: () => this.export(ExportType.Excel, false)
                    },
                    {
                        label: 'Csv',
                        icon: 'faSolidFileCsv',
                        iconOnly: true,
                        variant: 'csv',
                        isVisible: () => this.canExportCSV(),
                        onClick: () => this.export(ExportType.Csv, false)
                    },
                    {
                        label: 'Pdf',
                        icon: 'faSolidFilePdf',
                        iconOnly: true,
                        variant: 'pdf',
                        isVisible: () => this.canExportPDF(),
                        onClick: () => this.export(ExportType.Pdf)
                    }
                ]
            }
        ];
    }

    getDefaultFilter() {
        return {};
    }

    /**
     * Function to get the base name of the query.
     * If the last character of the query name is 's', it removes the 's' from the end.
     *
     * @returns The base name of the query, e.g. 'ServiceRequests' -> 'ServiceRequest'
     */
    getBaseName(): string {
        return this.queryName[this.queryName.length - 1] === 's' ? this.queryName.slice(0, -1) : this.queryName;
    }

    /**
     * Function to get the processed base name of the query.
     * It splits the base name from {@link getBaseName} by capital letters.
     *
     * @returns The processed base name of the query, e.g. 'ServiceRequest' -> 'Service Request'
     */
    getProcessedBaseName(): string {
        const baseName = this.getBaseName();
        return baseName.replace(/([A-Z])/g, ' $1').trim();
    }

    ngOnInit() {
        this.initialize();
    }

    initialize() {
        this.filter = this.getFilter();
        this.search();
    }

    queryListData() {
        return this.common.queryService.getQuery<TQuery>(this.queryName, this.filter, {
            injector: this.common.injector
        });
    }

    prefetchListData(customFilter = null) {
        return this.common.queryService.prefetch(this.queryName, customFilter || this.filter);
    }

    search(customGrid = null) {
        this.selection = [];
        const grid = customGrid || (this.appGrid?.grid as GridComponent);
        const { pageSize, skip } = grid ?? { pageSize: 10, skip: 0 };
        const { field, dir } = (grid?.sort || environment.settings.grid.sort)?.[0] || {};
        this.filter = { ...this.filter, skip, take: pageSize, orderBy: field, orderDirection: dir };
        this.saveFilter();
        this.query = this.queryListData().result;
        //Prefetch next page
        this.prefetchListData({ ...this.filter, skip: skip + pageSize, take: pageSize });
    }

    private getFilter() {
        const persistedFilter = this.getPersistedFilter();
        const filter = this.getDefaultFilter();
        return this.persistFilter && _.isObject(filter) ? { ...filter, ...persistedFilter } : filter;
    }

    saveFilter() {
        this.rememberStateService.set(`filter:${this.router.url}`, JSON.stringify(this.filter));
    }

    clearFilter(name?: string) {
        if (name) {
            this.filter[name] = null;
        } else {
            this.filter = this.getDefaultFilter();
        }
        this.search();
    }

    async filterDialog(filterProperties: FormProperty[], dialogSettings: DialogSettings = { width: '70%' }) {
        const data = await this.dialogService.form({
            options: {
                title: 'Filter',
                cancelAction: () => this.clearFilter(),
                cancelText: 'Clear',
                cancelIcon: 'faSolidFilterCircleXmark',
                confirmText: 'Filter',
                confirmIcon: 'faSolidFilter',
                model: this.filter,
                properties: filterProperties
            },
            dialogSettings
        });
        if (data instanceof DialogCloseResult || typeof data !== 'object') return;
        this.filter = data;
        this.search();
        this.resetPaging();
    }

    toggleFilter() {
        this.filterOpen = !this.filterOpen;
    }

    protected getPersistedFilter() {
        if (this.persistFilter === false) return {};

        try {
            const filter = this.rememberStateService.get<string>(`filter:${this.router.url}`);
            return JSON.parse(filter);
        } catch (error) {
            return {};
        }
    }

    canCreateNew() {
        if (this.createPermission) return this.user?.hasPermission(this.createPermission);
        return true;
    }

    createNew() {
        this.router.navigate([`${this.parentRoute}/create/`]);
    }

    actionHasPermission(permission) {
        return this.user?.hasPermission(permission);
    }

    public canExportPDF() {
        return !!this.exportPDFCommand;
    }

    public canExportExcel() {
        return !!this.exportExcelCommand;
    }

    public canExportCSV() {
        return !!this.exportCSVCommand;
    }

    async export(exportType: ExportType, allData = false, customGrid = null) {
        const grid = customGrid || (this.appGrid.grid as GridComponent);
        const data: any = {
            columnDefs: grid.columns
                .toArray()
                .filter((x: any) => x.field)
                .map((x: any) => ({
                    header: x.displayTitle,
                    field: x.field
                })),
            exportType,
            query: this.filter ?? {},
            selectedIds: this.selection
        };
        if (allData === true) data.query.skip = data.query.take = 0;

        const exportMutation = this.common.queryService.getCommandMutation();
        const exportCommands = {
            [ExportType.Pdf]: this.exportPDFCommand,
            [ExportType.Excel]: this.exportExcelCommand,
            [ExportType.Csv]: this.exportCSVCommand
        };

        const command = exportCommands[exportType] || null;

        return exportMutation
            .mutateAsync({ command, data })
            .then((res: any) => res && UploadActionButtonsComponent.download(res.fileName, res.content));
    }

    saveGridSettings(settings?: { value: GridSettings; id?: number }) {
        const saveModel = {
            key: this.common.getSanitizedUrl(),
            value: JSON.stringify(settings.value)
        };

        this.common.queryService
            .getCommandMutation()
            .mutateAsync({
                command: 'SaveGridSetting',
                data: { saveModel, id: settings?.id, action: settings?.id ? ViewMode.edit : ViewMode.create },
                invalidate: 'gridSettings'
            })
            .catch((err) => {
                this.toastrNotificationService.show({ type: 'error', message: 'Error saving grid settings' });
                return err;
            });
    }

    deleteGridSettings() {
        this.common.queryService
            .getCommandMutation()
            .mutateAsync({
                command: 'DeleteGridSetting',
                data: { settingKey: this.common.getSanitizedUrl() },
                invalidate: 'gridSettings'
            })
            .catch((err) => {
                this.toastrNotificationService.show({ type: 'error', message: 'Error clearing grid settings' });
                return err;
            });
    }

    get nrOfActiveFilters(): number {
        const clone = _.clone(this.getFilter());
        delete clone.skip;
        delete clone.take;
        delete clone.orderBy;
        delete clone.orderDirection;

        if (['VesselAnnouncement', 'Attachments'].some((name) => this.queryName.includes(name))) delete clone.id;
        if (this.queryName === 'WorkflowApprovals') {
            delete clone.entityId;
            delete clone.entityType;
            delete clone.statusId;
        }
        if (['LoadDiscList', 'ShippingLineList', 'BerthRequests'].some((x) => this.queryName.includes(x))) {
            delete clone.vesselVisitId;
            if (this.queryName.includes('LoadDiscList')) delete clone.load;
        }

        return (
            Object.values(clone).filter((x) => ![null, undefined].includes(x) && x?.toString()?.length > 0)?.length || 0
        );
    }

    get listOfActiveFilters(): { label: string; value: string }[] {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { skip, take, orderBy, orderDirection, ...filters } = this.getFilter();
        return (
            Object.entries(filters)
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                ?.filter(([label, value]) => value)
                .map(([label, value]) => ({ label, value: value?.toString() }))
        );
    }

    onSelectedKeysChange(keys: any[]) {
        this.selection = keys;
    }

    /**
     * Executes a command with the provided parameters.
     *
     * @param params - The parameters for the command execution.
     * @param params.command - The command to be executed.
     * @param params.data - Optional data to be sent with the command. Defaults to an empty object.
     * @param params.invalidate - Optional query name to invalidate. Defaults to the current query name.
     * @param params.customCallback - Optional custom callback function to be executed after the command. Defaults to clearing the selection.
     * @param params.showSpinner - Optional flag to show a spinner during the command execution.
     * @returns A promise that resolves with the result of the command execution.
     */
    executeCommand(params: CommandParams): Promise<any> {
        const {
            command,
            data = {},
            invalidate = this.queryName,
            customCallback = () => (this.selection = []),
            showSpinner
        } = params;
        const ids = this.appGrid.selectBy === 'row' ? this.selection.map((x) => x.id) : this.selection;

        return this.common.executeCommand({
            command,
            data: { ids, ...data },
            customCallback,
            invalidate,
            showSpinner
        });
    }

    /**
     * Executes a workflow command with the provided parameters.
     *
     * @param params - The parameters for the command execution.
     * @param params.command - The command to be executed.
     * @param params.data - Additional data to be sent with the command. Defaults to an empty object.
     * @param params.customCallback - A custom callback function to be executed after the command. Defaults to a function that clears the selection.
     * @param params.invalidate - The query name or an array of query names to be invalidated. Defaults to the query name of the current instance.
     * @param params.baseName - The base name of the entity. Defaults to the result of `getBaseName()` method.
     * @param params.showSpinner - A flag indicating whether to show a spinner during the command execution.
     * @returns A promise that resolves when the command execution is complete.
     */
    executeWorkflowCommand(params: CommandParams): Promise<any> {
        const {
            command,
            data = {},
            customCallback = () => {
                this.selection = [];
            },
            invalidate = this.queryName,
            baseName = this.getBaseName(),
            showSpinner
        } = params;

        const ids = this.appGrid.selectBy === 'row' ? this.selection.map((x) => x.id) : this.selection;
        const stateFromCode = this.appGrid.selectBy === 'row' ? this.selection?.[0]?.statusId : null;

        return this.common.executeWorkflowCommand({
            command,
            data: { ids, entityType: baseName, stateFromCode, ...data },
            customCallback,
            invalidate: Array.isArray(invalidate) ? invalidate.join(',') : `${invalidate},${baseName}List`,
            showSpinner
        });
    }

    private resetPaging() {
        this.appGrid.onDataStateChanged({
            skip: 0,
            take: this.appGrid?.grid?.pageSize || environment.settings.grid.pageSize
        });
    }
}
